import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 11; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/28-dec-23-2/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function AssemblyChristmas2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Assembly on Christmas by Primary</h1>

        <p>
          Chinmaya Vidyalaya celebrated Christmas in a Special Assembly conducted by the enthusiastic learners of class I.
          <br /><br />
          The celebrations started with the lyrical ‘Rudolph the Red-Nosed Reindeer’ and was followed by an interesting skit depicting the Nativity scene that explained the story of the birth of Christ.
          <br /><br />
          A deep and informative talk explained the significance and true essence of Christmas. It was followed by a mesmerising dance performance on “Jingle Bells’.
          <br /><br />
          Children’s favourite moment was when Santa Claus made an appearance on his sleigh.
          <br /><br />
          The event was a resounding success, with the learners cheering and clapping after each performance.
          <br /><br />
          They also learned important life lessons on sharing and giving.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
